import React          from 'react';
import Layout         from '../components/Layout/Layout';
import { graphql }    from 'gatsby';
import Helmet         from 'react-helmet';
import './chi-siamo.scss';
import { useIntl }    from 'gatsby-plugin-intl';
import img1           from '../images/assibro-sede-min.jpg';
import img2           from '../images/chi-siamo-2.jpg';
import img3           from '../images/chi-siamo-3.jpg';
import { warning200 } from '../utils/colors';
import HomepageBlock  from '../components/Homepage/HomepageBlock/HomepageBlock';
import { navigate }   from 'gatsby-link';

const ChiSiamo = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout categories={data.prismic.allMacrocategorys.edges.map(el => el.node)}
            documents={data.prismic.allDocumentss.edges[0].node}>
      <Helmet title={'Chi siamo'} titleTemplate={`Assibro | %s`} meta={[
        {
          name: `description`,
          content: 'Assibro',
        },
        {
          property: `og:title`,
          content: 'Assibro',
        },
        {
          property: `og:description`,
          content: 'Assibro',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Assibro',
        },
        {
          name: `twitter:title`,
          content: 'Assibro',
        },
        {
          name: `twitter:description`,
          content: 'Assibro',
        },
      ]}/>
      <div className="who-are-we">
        <div className="who-are-we__hero">
          <div className="who-are-we__hero__caption">
            <h1>{intl.formatMessage({ id: 'who-are-we.hero-title' })}</h1>
            <p>{intl.formatMessage({ id: 'who-are-we.hero-text' })}</p>
          </div>
        </div>
        <div className="who-are-we__content">
          <div className="who-are-we__content__block">
            <div className="who-are-we__content__block__text">
              <h2>{intl.formatMessage({id: 'who-we-are.block-1-title'})}</h2>
              <h4>{intl.formatMessage({id: 'who-we-are.block-1-subtitle'})}</h4>
              <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'who-we-are.block-1-text'})}}></p>
            </div>
            <div className="who-are-we__content__block__image">
              <img src={img1} alt=""/>
              <div className="yellow-square"></div>
            </div>
          </div>
          <div className="who-are-we__content__block who-are-we__content__block--reverse">
            <div className="who-are-we__content__block__image">
              <img src={img2} alt=""/>
              <div className="yellow-square"></div>
            </div>
            <div className="who-are-we__content__block__text">
              <h2>{intl.formatMessage({id: 'who-we-are.block-2-title'})}</h2>
              <h4>{intl.formatMessage({id: 'who-we-are.block-2-subtitle'})}</h4>
              <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'who-we-are.block-2-text'})}}></p>
            </div>
          </div>
          <div className="who-are-we__content__block">
            <div className="who-are-we__content__block__text">
              <h2>{intl.formatMessage({id: 'who-we-are.block-3-title'})}</h2>
              <h4>{intl.formatMessage({id: 'who-we-are.block-3-subtitle'})}</h4>
              <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'who-we-are.block-3-text'})}}></p>
            </div>
            <div className="who-are-we__content__block__image">
              <img src={img3} alt=""/>
              <div className="yellow-square"></div>
            </div>
          </div>
        </div>
        <HomepageBlock mode={'cta-handshakes'}
                        title={intl.formatMessage({ id: 'homepage.sixth-hero-title' })}
                        description={[intl.formatMessage({ id: 'homepage.sixth-hero-subtitle' })]}
                        bulletList={[{
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-1' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-1-text' }),
                        }, {
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-2' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-2-text' }),
                        }, {
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-3' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-3-text' }),
                        }]}
                        cta={{
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-cta' }),
                          action: () => navigate('/contattaci'),
                        }}
                        hasBg={warning200}/>
      </div>
    </Layout>
  );
};

export default ChiSiamo;

export const query = graphql`
    {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
